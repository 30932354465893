@mixin breakpoint($point) {
  @if $point == xl-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == x-large {
    @media screen and (max-width: 1000px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 700px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}
$tint-color: #f58b8b;

.App {
  button.rounded {
    background-color: $tint-color;
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 35px;
    padding: 8px;

    a {
      color: white;
      text-decoration: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .intro-holder {
    background: url(./assets/images/pinkcirclebg.jpg) no-repeat center center fixed;
    background-color: #FBCDCD;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      margin-bottom: 40px;
      transition: none;

      &.animation-zero {
        opacity: 0;
        transform: scale(0.5);

        h1:not(.mast-text) {
          opacity: 0;
        }
      }

      &.animation-one {
        transition: all 600ms cubic-bezier(0.42, 0, 0.58, 1); /* ease-in-out */

        opacity: 1;
        transform: scale(1.2);

        h1:not(.mast-text) {
          opacity: 0;
        }
      }

      &.animation-two {
        transition: all 400ms cubic-bezier(0.5, 0.25, 0.165, 1.385); /* custom */

        opacity: 1;
        transform: scale(1);

        h1:not(.mast-text) {
          opacity: 0;
          transform: translateY(70%) scale(0.9);
        }
      }

      &.animation-three {
        transition: all 600ms cubic-bezier(0.42, 0, 0.58, 1); /* ease-in-out */

        opacity: 1;
        transform: scale(1);

        h1:not(.mast-text) {
          transition: all 600ms cubic-bezier(0.42, 0, 0.58, 1); /* ease-in-out */
          opacity: 1;
          transform: translateY(0);
        }
      }

      h1 {
        font-size: 4rem;
        text-align: center;

        &.mast-text {
          font-size: 4.5rem;

          @include breakpoint(medium) {
            font-size: 2.5rem;
          }

          @include breakpoint(small) {
            font-size: 2rem;
          }
        }

        &:not(.mast-text) {
          transition: none;
          margin-top: 16px;
        }

        @include breakpoint(medium) {
          max-width: 100%;
          font-size: 2rem;
        }

        @include breakpoint(small) {
          font-size: 1.5rem;
        }
      }

      .mast {
        * {
          display: inline;
          vertical-align: middle;
        }

        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
          object-fit: contain;
          max-height: 180px;
          margin-left: 32px;
          margin-right: 8px;

          @include breakpoint(medium) {
            max-width: 50%;
            margin-left: 12px;
            margin-right: 0px;
          }

          @include breakpoint(small) {
            min-width: 40%;
          }
        }
      }
    }

    .top-half-circle {
      z-index: -999;
      height: 70vh;
      position: absolute;

      @include breakpoint(medium) {
        height: 50vh;
      }
    }

    .bottom-half-circle {
      z-index: -999;
      height: 70vh;
      position: absolute;
      right: 0;
      bottom: 0;

      @include breakpoint(medium) {
        height: 50vh;
      }

      @include breakpoint(small) {
        display: none;
        height: 0;
      }
    }
  }

  .beliefs-holder {
    background-color: black;
    color: white;
    margin: 0;
    padding: 164px 0px;

    * {
      margin: 0;
    }

    text-align: center;

    * {
      display: block;
      margin-left: auto;
      margin-right: auto;

      padding: 0 32px;
      max-width: 1200px;

      @include breakpoint(x-large) {
        max-width: 90%;
      }
    }

    h1 {
      font-weight: 400;
    }

    h2 {
      margin-top: 100px;
      font-weight: 600;
    }

    h1,
    h2 {
      font-size: 3.5rem;

      @include breakpoint(x-large) {
        font-size: 3rem;
      }

      @include breakpoint(medium) {
        font-size: 2rem;
      }

      @include breakpoint(small) {
        font-size: 1.5rem;
      }
    }
  }

  .church-goals {
    margin: 0;
    padding: 164px 0px;

    * {
      margin: 0;
    }

    text-align: center;

    * {
      margin-left: auto;
      margin-right: auto;

      max-width: 1200px;

      @include breakpoint(x-large) {
        max-width: 90%;
      }
    }

    a {
      color: #f58b8b;
    }

    img {
      border-radius: 32px;
      width: 200px;
    }

    h2 {
      margin-top: 16px;
      font-weight: 600;
    }

    h2 {
      font-size: 3.5rem;

      @include breakpoint(x-large) {
        font-size: 3rem;
      }

      @include breakpoint(medium) {
        font-size: 2rem;
      }

      @include breakpoint(small) {
        font-size: 1.5rem;
      }
    }

    p {
      margin-top: 12px;
      font-size: 2.5rem;

      @include breakpoint(x-large) {
        font-size: 2.5rem;
      }

      @include breakpoint(medium) {
        font-size: 1.5rem;
      }

      @include breakpoint(small) {
        font-size: 1.25rem;
      }
    }
  }

  .tech-intro-holder {
    text-align: left;
    margin-top: 72px;
    font-size: 3rem;
    font-weight: 620;
    padding: 0 32px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(medium) {
      font-size: 2rem;
    }

    @include breakpoint(small) {
      font-size: 1.2rem;

      p {
        a {
          margin-top: 12px;
          display: block;
        }
      }
    }

    p {
      a {
        margin-left: 8px;
        color: $tint-color;
      }
    }
  }

  .feature {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    max-width: 1200px;
    padding: 0 32px;

    overflow: hidden;

    justify-content: center;
    align-items: center;

    &.feature-reverse {
      flex-direction: row-reverse;
      column-gap: 32px;

      @include breakpoint(medium) {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
      }
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 0;
    }

    h3 {
      font-size: 2.5rem;
      font-weight: 520;
    }

    p {
      font-size: 2rem;
      font-weight: 520;
      margin-top: 0.5rem;
    }

    a {
      font-size: 2.5rem;
      font-weight: 520;
      text-decoration: underline;
      color: $tint-color;

      &:hover {
        cursor: pointer;
      }
    }

    video,
    img {
      max-width: 400px;
      max-height: 70%;

      @include breakpoint(large) {
        max-width: 320px;
      }

      @include breakpoint(medium) {
        max-width: 270px;
        margin-left: -16px;
      }
    }

    @include breakpoint(x-large) {
      h1 {
        font-size: 2.5rem;

        &.full {
          font-size: 3.25rem;
        }
      }

      a,
      h3 {
        font-size: 3rem;
      }

      p {
        font-size: 2rem;
      }
    }

    @include breakpoint(large) {
      h1 {
        font-size: 3rem;
      }

      a,
      h3 {
        font-size: 3rem;
      }

      p {
        font-size: 1.5rem;
      }
    }

    @include breakpoint(medium) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;

      h1 {
        font-size: 2rem;

        &.full {
          font-size: 2rem;
        }
      }

      a,
      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .feature-extra {
    font-size: 1.5rem;
    padding: 0 32px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    h1 {
      font-weight: 600;
    }

    @include breakpoint(medium) {
      font-size: 1rem;
    }
  }

  .beta {
    background: url(./assets/images/pinkcirclebg.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    // Container
    div {
      display: flex;
      margin-left: auto;
      margin-right: auto;

      flex-direction: column;

      &.container {
        max-width: 1200px;
        padding: 0 32px;
      }

      .copy {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 0;

        @include breakpoint(medium) {
          font-size: 1.5rem;
        }
      }

      .pricing {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        text-align: center;

        * {
          margin: 0;
        }

        h1 {
          font-size: 6rem;
        }

        h2 {
          font-size: 3rem;
        }

        button {
          margin-top: 2rem;
          font-size: 2rem;
          padding: 16px 48px;
          border-radius: 64px;

          transition: transform 0.2s ease-out;

          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }

        @include breakpoint(large) {
          h1 {
            font-size: 5rem;
          }

          h2 {
            font-size: 3rem;
          }

          button {
            font-size: 2rem;
          }
        }
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 6rem;
      }
    }
  }

  footer {
    margin-top: 100px;
    margin-bottom: 48px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
  }
}
